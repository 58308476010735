<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->
      <el-form
        ref="form"
        label-width="80px"
        size="mini"
      >
        <div class="flexparents">
          开始日期:
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          结束日期:
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>

          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="cleardata()"
          >清空</el-button>
          <el-button
            type="primary"
            :disabled="loading"
            @click="loadArticles(1)"
          >查询</el-button>
          <el-button
            type="info"
            :disabled="loading"
            @click="ifgaoji = !ifgaoji"
          >{{ ifgaoji ? "收起" : "高级查询" }}</el-button>
          <div
            v-if="ifgaoji"
            class="flexparents zdhh"
          >
            <div>
              单号:
              <el-input
                class="input rightgap"
                v-model="form.workOrderNo"
                placeholder="请输入单号"
              ></el-input>
            </div>
            <div>
              客户原话:
              <el-input
                class="input rightgap"
                v-model="form.customerDescription"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              国家:
              <el-input
                class="input rightgap"
                v-model="form.country"
                placeholder="请输入国家"
              ></el-input>
            </div>

            <div>
              邮箱:
              <el-input
                class="input rightgap"
                v-model="form.email"
                placeholder="请输入email"
              ></el-input>
            </div>
            <div>
              imei:
              <el-input
                class="input rightgap"
                v-model="form.imei"
                placeholder="请输入imei"
              ></el-input>
            </div>

          </div>
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div
        slot="header"
        class="clearfix"
      >
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>
      <el-table
        :data="articles"
        stripe
        :height="tableHeight"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="orderNo"
          label="工单号"
        > </el-table-column>
        <el-table-column label="问题状态">
          <template slot-scope="scope">
            <el-tag :type="processStatus[scope.row.process].type">{{
              processStatus[scope.row.process].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="faeOperatorName"
          label="FAE处理人"
        > </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
        > </el-table-column>
        <el-table-column
          prop="purchaseChannel"
          label="购买渠道"
        > </el-table-column>
        <el-table-column
          prop="name"
          label="客户名字"
        >
        </el-table-column>

        <el-table-column
          prop="company"
          label="公司"
        > </el-table-column>
        <el-table-column
          prop="productModelType"
          label="机型"
        > </el-table-column>
        <el-table-column
          prop="imei"
          label="IMEI"
        > </el-table-column>
        <el-table-column
          prop="country"
          label="国家"
        > </el-table-column>
        <el-table-column
          prop="area"
          label="大区"
          key="area"
        >
          <template v-slot="scope">
            {{
              areaoptions[scope.row.area] && areaoptions[scope.row.area].label
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="customerDescription"
          width="350"
          label="客户原话"
        >
        </el-table-column>

        <el-table-column
          prop="createdTime"
          label="创建时间"
        > </el-table-column>
        <el-table-column
          prop="completedTime"
          label="完成时间"
        >
        </el-table-column>

        <el-table-column label="紧急程度">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="urgentStatus[scope.row.urgentType].type">{{
              urgentStatus[scope.row.urgentType].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          width="60"
          label="操作"
          fixed="right"
        >
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="getdetaile(scope.row.workOrderId)"
                  circle
                  icon="el-icon-search"
                  type="primary"
                ></el-button>
              </el-tooltip>

            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>

    <el-dialog
      title="工单详情:"
      modal-append-to-body
      :visible.sync="showOrder"
      width="1100px"
    >

      <el-steps
        :active="steptype"
        finish-status="success"
        class="gap"
        style="padding-left: 16px; padding-right: 16px"
      >
        <el-step
          :title="$t('jindu1')"
          :description="FAEdatile.createdTime"
        ></el-step>
        <el-step
          :title="$t('jindu2')"
          :description="FAEdatile.firstBrowseTime"
        ></el-step>
        <el-step
          :title="$t('jindu3')"
          :description="yanfadatile.createdTime"
        ></el-step>
        <el-step
          :title="$t('jindu4')"
          :description="yanfadatile.firstBrowseTime"
        ></el-step>
        <el-step
          :title="$t('jindu5')"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step
          :title="$t('jindu6')"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step
          :title="$t('jindu7')"
          :description="FAEdatile.completedTime"
        ></el-step>
      </el-steps>

      <div class="destitle">基本信息</div>
      <el-descriptions
        class="desbasebody"
        :column="3"
      >
        <el-descriptions-item label="订单号">{{
          FAEdatile.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="型号">{{
          FAEdatile.productModelType
        }}</el-descriptions-item>
        <el-descriptions-item label="地区">{{
          areaoptions[FAEdatile.area] && areaoptions[FAEdatile.area].label
        }}</el-descriptions-item>
        <el-descriptions-item label="客诉类型">{{
          problemtypeaoptions[FAEdatile.type] &&
          problemtypeaoptions[FAEdatile.type].label
        }}</el-descriptions-item>
        <el-descriptions-item label="涉及平台">{{
          pingtaioptions[FAEdatile.platform] &&
          pingtaioptions[FAEdatile.platform].label
        }}</el-descriptions-item>
        <el-descriptions-item label="紧急程度">
          {{
            urgentStatus[FAEdatile.urgentType]
              ? urgentStatus[FAEdatile.urgentType].text
              : ""
          }}
        </el-descriptions-item>
        <el-descriptions-item label="FAE处理人">{{
          FAEdatile.faeOperatorName
        }}</el-descriptions-item>
        <el-descriptions-item label="研发跟进人">{{
          FAEdatile.personLiable
        }}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{
          FAEdatile.createdName
        }}</el-descriptions-item>
      </el-descriptions>

      <template>
        <div class="destitle">官网工单详情</div>
        <div class="desbody">
          <el-descriptions :column="4">
            <el-descriptions-item label="客户姓名">{{
              websiteWorkOrderVo.name
            }}</el-descriptions-item>
            <el-descriptions-item label="机型">{{
              websiteWorkOrderVo.productModelType
            }}</el-descriptions-item>
            <el-descriptions-item label="国家">{{
              websiteWorkOrderVo.country
            }}</el-descriptions-item>
            <el-descriptions-item label="编码">{{
              websiteWorkOrderVo.code
            }}</el-descriptions-item>

            <el-descriptions-item label="imei">{{
              websiteWorkOrderVo.imei
            }}</el-descriptions-item>
            <el-descriptions-item label="购买渠道">{{
              websiteWorkOrderVo.purchaseChannel
            }}</el-descriptions-item>
            =
            <el-descriptions-item
              :span="2"
              label="邮件"
            >{{
              websiteWorkOrderVo.email
            }}</el-descriptions-item>

          </el-descriptions>
          <el-descriptions
            direction="vertical"
            :column="4"
          >
            <el-descriptions-item
              label="客户原话"
              :span="4"
            >
              <p style="white-space: pre-line">
                {{ websiteWorkOrderVo.customerDescription }}
              </p>
            </el-descriptions-item>

            <el-descriptions-item
              label="图片"
              :span="16"
            >
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                v-for="{ url } in websiteWorkOrderVo.image"
                :key="url"
                :src="url"
                :previewSrcList="ESpreviewList"
              />
            </el-descriptions-item>
            <el-descriptions-item
              label="附件"
              :span="16"
            >
              <p
                v-for="{ url, path } in websiteWorkOrderVo.attachment"
                :key="url"
              >
                <a
                  :href="url"
                  target="_blank"
                >{{ path }}</a>
              </p>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </template>

      <div class="destitle">FAE详情</div>
      <div class="desbody">
        <el-descriptions :column="3">
          <el-descriptions-item label="关键字">{{
            FAEdatile.keyword
          }}</el-descriptions-item>
          <el-descriptions-item label="当前卡点">{{
            difoptions[FAEdatile.stuckPoint] &&
            difoptions[FAEdatile.stuckPoint].label
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            FAEdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          direction="vertical"
          :column="3"
        >
          <el-descriptions-item
            label="问题处理方案"
            :span="3"
          >
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in solutionlinedata"
                :key="index"
                :timestamp="activity.time"
              >
                <p style="white-space: pre-line">
                  {{ activity.detail }}
                </p>
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>
          <el-descriptions-item
            label="描述"
            :span="3"
          >
            <p style="white-space: pre-line">
              {{ FAEdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item
            label="客户原话/其他"
            :span="3"
          >{{
            FAEdatile.customerDescription
          }}</el-descriptions-item>
          <el-descriptions-item
            label="图片"
            :span="3"
          >
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in FAEdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="FAEpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item
            label="附件"
            :span="3"
          >
            <p
              v-for="{ url, path } in FAEdatile.attachment"
              :key="url"
            >
              <a
                :href="url"
                target="_blank"
              >{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <template v-if="this.yanfadatile.orderNo">
        <div class="destitle">研发详情</div>
        <div class="desbody">
          <el-descriptions :column="3">
            <el-descriptions-item :label="$t('createdTime')">{{
              yanfadatile.createdTime
            }}</el-descriptions-item>
            <el-descriptions-item :label="$t('zhuangtai')">{{
              attributeoptions[yanfadatile.attribute] &&
              attributeoptions[yanfadatile.attribute].label
            }}</el-descriptions-item>
            <el-descriptions-item :label="$t('personLiable')">{{
              yanfadatile.personLiable
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            direction="vertical"
            :column="3"
          >
            <el-descriptions-item
              label="解释说明"
              :span="3"
            >
              <el-timeline>
                <el-timeline-item
                  v-for="(activity, index) in remakelinedate"
                  :key="index"
                  :timestamp="activity.time"
                >
                  {{ activity.detail }}
                </el-timeline-item>
              </el-timeline>
            </el-descriptions-item>

            <el-descriptions-item
              :label="$t('tapdlianjie')"
              :span="3"
            >{{
              yanfadatile.tapdLink
            }}</el-descriptions-item>

            <el-descriptions-item
              :label="$t('yfkd')"
              :span="3"
            >{{
              yanfadatile.developStuckPoint
            }}</el-descriptions-item>

            <el-descriptions-item
              :label="$t('jjfangan')"
              :span="3"
            >{{
              yanfadatile.solution
            }}</el-descriptions-item>

            <el-descriptions-item
              label="图片"
              :span="3"
            >
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                fit="fit"
                v-for="{ url } in yanfadatile.imageInfos"
                :key="url"
                :src="url"
                :previewSrcList="YFpreviewList"
              />
            </el-descriptions-item>

            <el-descriptions-item
              label="附件"
              :span="3"
            >
              <p
                v-for="{ url, path } in yanfadatile.fileInfos"
                :key="url"
              >
                <a
                  :href="url"
                  target="_blank"
                >{{ path }}</a>
              </p>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </template>

    </el-dialog>

  </div>
</template>
<script>
import { getwebsitpageUrl, getOneFaeUrl } from "@/api/fae";
import {
  productModelTypeOptions,
  areaoptions,
  problemtypeaoptions,
  prodectoptions,
  netoptions,
  pingtaioptions,
  difoptions,
  attributeoptions,
} from "@/options/index";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      productModelTypeOptions,
      areaoptions,
      problemtypeaoptions,
      prodectoptions,
      netoptions,
      pingtaioptions,
      difoptions,
      attributeoptions,

      steptype: 0,
      editform: {
        workOrderId: null,
        deviceModel: null,
        imei: null,
        iccid: null,
        simCardOperator: null,
        description: null,
        remark: null,
      },
      reopenform: {},
      innerVisible: false,
      showAssignFaeOperator: false,
      showChangeES: false,
      FAElist: [],
      ECSaleslist: [],
      showSolveDio: false,
      showReopen: false,
      previewList: [],
      ESpreviewList: [],
      tableHeight: window.innerHeight - 320,
      loading: true, // 表单数据加载中 loading
      articles: [], // 文章数据列表
      totalCount: 0, // 总数据条数
      ifgaoji: false,
      form: {
        ownerType: 7,
        createdByStations: 7,
        creatTimeBefore: null,
        createTimeAfter: null,
        size: 20, // 每页大小
        page: 1, // 当前页码

        workOrderNo: null,
        customerDescription: null,
        email: null,
        imei: null,
        country: null,
      },
      setFaeManForm: {
        workOrderId: null,
        faeOperatorName: null,
      },
      changefollowForm: {
        workOrderId: null,
        followPersonName: null,
      },
      EsOrderSolveform: {
        workOrderId: null,
        solution: null,
        remark: null,
      },

      stationsoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 5,
          label: "电商客户服务",
        },
        {
          value: 2,
          label: "FAE",
        },
        {
          value: 3,
          label: "研发",
        },
      ],
      transformFlagoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      typeoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "0",
          label: "Device Inactive",
        },
        {
          value: "1",
          label: "Device Offline",
        },
        {
          value: "2",
          label: "IMEI Not Existed",
        },
        {
          value: "3",
          label: "I already paid, still not working",
        },
        {
          value: "4",
          label: "Wrong location",
        },
        {
          value: "5",
          label: "About Alert",
        },
        {
          value: "6",
          label: "About Subscription",
        },
        {
          value: "7",
          label: "About Battery / Charging",
        },
      ],
      usageScenariooption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "0",
          label: "Vehicle",
        },
        {
          value: "1",
          label: "Personal",
        },
        {
          value: "2",
          label: "Asset",
        },
        {
          value: "3",
          label: "Other",
        },
      ],
      processStatus: [
        { status: 0, text: "开启", type: "primary" },
        { status: 1, text: "完成", type: "success" },
        { status: 2, text: "待评价", type: "info" },
        { status: 3, text: "已转出", type: "warning" },
        { status: 4, text: "已完成", type: "warning" },
        { status: 5, text: "待完成", type: "info" },
      ],

      usageScenarioStatus: [
        { status: 0, text: "Vehicle", type: "info" },
        { status: 1, text: "Personal", type: "info" },
        { status: 2, text: "Asset", type: "info" },
        { status: 3, text: "Other", type: "info" },
      ],
      progressStatus: [
        { text: "个人", type: "warning" }, // 0
        { text: "客服", type: "success" }, // 1
        { text: "FAE", type: "primary" }, // 2
        { text: "研发", type: "danger" }, // 3
        { text: "小程序", type: "success" }, // 3
        { text: "电商", type: "success" }, // 3
      ],

      urgentStatus: [
        { status: 0, text: this.$t("di"), type: "info" }, // 1
        { status: 1, text: this.$t("zhong"), type: "primary" }, // 1
        { status: 2, text: this.$t("gao"), type: "warning" }, // 1
        { status: 3, text: this.$t("jigao"), type: "danger" }, // 1
      ],
      esservedatiledata: {},
      FAEdatile: {},
      yanfadatile: {},
      websiteWorkOrderVo: {},
      showOrder: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.form.workOrderNo = this.$route.query.orderNo;
  },
  destroyed() {
    window.removeEventListener("resize", this.getTableHeight);
  },
  mounted() {
    this.loadArticles(1);
  },
  methods: {
    async getdetaile(id) {
      try {
        const res = await getOneFaeUrl({
          workOrderId: id,
        });
        if (res.data.code == 200) {
          this.FAEdatile = res.data.data.technicalSupportWorkOrderVo;
          if (res.data.data.developmentWorkOrderVo) {
            this.yanfadatile = {
              ...res.data.data.developmentWorkOrderVo,
            };
          }
          this.websiteWorkOrderVo = {
            ...res.data.data.websiteWorkOrderVo,
            attachment: (res.data.data.websiteWorkOrderVo.fileInfos || []).map(
              ({ path, url }) => ({
                path,
                url,
              })
            ),
            image: (res.data.data.websiteWorkOrderVo.image || []).map(
              ({ path, url }) => ({
                path,
                url,
              })
            ),
          };
          this.ESpreviewList = (
            res.data.data.websiteWorkOrderVo?.imageInfos || []
          ).map(({ url }) => url);

          if (res.data.data.technicalSupportWorkOrderVo.process == 0) {
            this.steptype = 1;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 3) {
            this.steptype = 3;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 5) {
            this.steptype = 5;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 1) {
            this.steptype = 7;
          } else {
            this.steptype = 5;
          }

          this.showOrder = true;
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    getTableHeight() {
      this.tableHeight = window.innerHeight - 320;
    },
    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },
    async loadArticles(page = 1) {
      this.loading = true;
      try {
        const res = await getwebsitpageUrl(this.form);
        if (res.data.code === 200) {
          this.articles = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },
  },
};
</script>

<style scoped lang="less">
.header {
  float: right;
}

.article-container {
  height: 100%;
}

.list-table {
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.filter-card {
  margin-bottom: 5px;
}
.flexparents {
  margin-bottom: 5px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}

.zdhh {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.floter {
  display: flex;
  justify-content: space-between;
}
/deep/.el-table .warning-row {
  background: oldlace;
  font-weight: bold;
  font-size: 12px;
}
/deep/.el-table .complate-row {
  background: #ffffff;
}
/deep/.el-table .white-row {
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
}

.destitle {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

/deep/.el-descriptions-item__label {
  font-weight: bold !important;
  color: rgb(0, 0, 0);
}

.desbasebody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
  background-color: #ecf4fd;
  /deep/.el-descriptions__table {
    background-color: #ecf4fd;
  }
}
.desbody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
}
</style>
